@font-face {
  font-family: 'Caros Soft';
  src: url('CarosSoft.otf') format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: 'Caros Soft';
  src: url('CarosSoftBold.otf') format('opentype');
  font-weight: 600;
}
